<!-- eslint-disable max-len -->
<template>
  <!-- 配送資訊 -->
  <div class="cart_block mb50">
    <h4 class="txt-bold">配送資訊</h4>
    <div class="delivery_area">
        <div class="delivery_item wifi">
          <!-- 產品資訊 -->
          <!-- <div class="product_name">
              <div class="pic">
                  <figure style="background-image: url(@/assets/img/shopping/product_2.png);"></figure>
              </div>
              <h6 class="txt-bold">
                  日本藍鑽石
                  <span class="txt-grey">與其他2項商品</span>
              </h6>
          </div> -->
          <!-- 填資料 -->
          <div class="item_in">
            <div class="fill_box">
              <p class="mg7">取件方式</p>
              <div class="fill_in">
                <template v-if="takeWay === '超取'">
                  <p class="col100">
                    <img class="delivery_icon" src="@/assets/img/shopping/FamilyMart.jpg" alt=""> 全家店到店
                  </p>
                  <p
                    v-if="cartForm.cvsspot && cartForm.cvsnum && cartForm.family_name"
                    class="col100">
                      {{cartForm.cvsspot}} {{cartForm.family_name}}
                  </p>
                  <span
                    target="_blank"
                    class="txt-orange"
                    style="text-decoration: revert;"
                    @click="
                      listenFamilyMart();
                    ">
                      請選擇店鋪
                  </span>
                </template>
                <template
                  v-else-if="takeWay === '宅配'">
                    <p>
                      <img class="delivery_icon" src="@/assets/img/shopping/icon_car.svg" alt=""> 宅配
                    </p>
                </template>
                <p
                  v-else-if="takeWay === '機場'">
                    機場取件
                </p>
              </div>
            </div>

            <div class="fill_box" v-if="takeWay === '宅配'">
              <p class="mg7">收件地址<span class="txt-redorange">*</span></p>
              <div class="fill_in">
                <div class="col100">
                  <input type="radio"
                    @input="$emit('selectType', $event)"
                    v-model="addressType"
                    name="product_loc"
                    id="same_with_member"
                    value="1"
                    checked>
                  <label for="same_with_member" class="p"><span></span> 同會員聯絡地址</label>
                  <input type="radio"
                    @input="$emit('selectType', $event)"
                    v-model="addressType"
                    name="product_loc"
                    id="near"
                    value="2">
                  <label for="near" class="p"><span></span> 最近收件地址</label>
                  <input type="radio"
                    @input="$emit('selectType', $event)"
                    v-model="addressType"
                    name="product_loc"
                    id="new_loc"
                    value="3">
                  <label for="new_loc" class="p"><span></span> 新收件地址</label>
                </div>
                <!-- 最近收件地址, 點選打開可以選擇 -->
                <div
                  class="col100"
                  v-if="addressType === '2' && historyAddress.length > 0">
                    <div class="loc_box">
                      <template v-for="(data, i) in historyAddress">
                        <input
                          type="radio"
                          name="recent_loc"
                          :key="`address_input_${i}`"
                          :value="data"
                          :id="data.id"
                          @input="$emit('historyData', data)">
                        <label :key="`address_label_${i}`" :for="data.id" class="txt-grey">
                          <span></span> {{data.address}}
                        </label>
                        <br :key="`address_br_${i}`">
                      </template>
                    </div>
                </div>
                <div class="col50 loc_custom">
                  <!-- 不開放編輯的時候, 加上readonly, 跟class="readonly" -->
                  <input type="text" v-model="cartForm.zipcode" class="readonly" readonly>
                </div>
                <div class="col50 loc_custom">
                </div>
                <div class="col50 loc_custom">
                  <!-- 不開放編輯的時候, 加上readonly, 跟class="readonly" -->
                  <select
                    :disabled="addressType === '1' || addressType === '2'"
                    v-model="cartForm.county"
                    @input="$emit('county', $event); getFilter()">
                      <option
                        v-for="(data, i ) in county"
                        :key="`county_${i}`"
                        :value="data">
                          {{data}}
                      </option>
                  </select>
                </div>
                <div class="col50 loc_custom">
                  <!-- 不開放編輯的時候, 加上readonly, 跟class="readonly" -->
                  <select
                    :disabled="addressType === '1' || addressType === '2'"
                    v-model="cartForm.district"
                    @input="$emit('district', $event); $emit('zipcode', getZip($event));getFilter()">
                      <option
                        v-for="(data, i ) in district"
                        :key="`district_${i}`"
                        :value="data">
                          {{data}}
                      </option>
                  </select>
                </div>
                <div class="col100">
                  <input type="text" v-model="cartForm.address">
                </div>
              </div>
            </div>

            <div class="fill_box">
              <p class="mg14">收件人<span class="txt-redorange">*</span></p>
              <div class="fill_in">
                <div class="col50">
                  <input type="text" v-model="cartForm.name">
                </div>
                <div class="col50">
                  <input type="radio"
                    v-model="cartForm.sex"
                    id="m"
                    value="m">
                  <label for="m" class="p">
                    <span></span> 先生
                  </label>
                  <input type="radio"
                    id="f"
                    v-model="cartForm.sex"
                    value="f">
                  <label for="f" class="p">
                    <span></span> 小姐
                  </label>
                </div>
              </div>
            </div>

            <div class="fill_box">
                <p class="mg14">Email<span class="txt-redorange">*</span></p>
                <div class="fill_in">
                  <div class="col50">
                      <input type="text" v-model="cartForm.email">
                  </div>
                </div>
            </div>

            <div class="fill_box">
                <p class="mg14">手機<span class="txt-redorange">*</span></p>
                <div class="fill_in">
                    <div class="col50">
                        <select v-model="cartForm.country_code">
                            <option v-for="(data, i) in countryPhoneCode"
                            :value="data.id"
                            :key="`countryPhoneCode_${i}`">{{data.text}}</option>
                        </select>
                    </div>
                    <div class="col50">
                        <input type="number" class="inputNumber" v-model="cartForm.mobile" placeholder="0912345678">
                    </div>
                </div>
            </div>

            <div class="fill_box">
                <p class="mg14">市話</p>
                <div class="fill_in">
                    <div style="width: 80px;">
                        <input type="number" class="inputNumber"  v-model="cartForm.tel">
                    </div>
                    <span style="margin: 0 15px">-</span>
                    <div style="flex: 1;">
                        <input type="number" class="inputNumber"  v-model="cartForm.tel_1">
                    </div>
                </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import requestApi from '@/lib/http/index';

export default {
  name: 'CartShip',
  props: ['cartForm', 'historyAddress'],
  data() {
    return {
      intervalFamilyMart: null,
      addressType: '1',
      county: [],
      district: [],
      zipData: [],
      countryPhoneCode: [],
      test: 'm',
    };
  },
  computed: {
    ...mapState([
      'takeWay',
      'user',
    ]),
  },
  methods: {
    getZip(e) {
      const zip = this.zipData.filter((item) => item.district === e.target.value);
      if (zip.length === 1) {
        console.log(zip[0].zipcode);
        return zip[0].zipcode;
      }
      return '';
    },
    listenFamilyMart() {
      window.open(`http://mfme.map.com.tw/default.aspx?cvsname=wiho-web.howdesign.com.tw&cvsid=${this.user.id}&&exchange=true`);

      // 先還原, 刪掉localStorage裡殘存的data
      localStorage.removeItem('fm');
      clearInterval(this.intervalFamilyMart);

      // 開始監聽
      this.intervalFamilyMart = setInterval(() => {
        if (this.$route.name !== 'CartDetail') {
          console.log('中斷監聽全家店舖資料');
          clearInterval(this.intervalFamilyMart);
        }
        if (!localStorage.getItem('fm') && this.$route.name === 'CartDetail') {
          console.log('監聽全家店舖資料中', localStorage.getItem('fm'));
        } else {
          const familyMartData = JSON.parse(localStorage.getItem('fm'));

          console.log('取得全家店舖資料：', familyMartData);

          this.cartForm.cvsspot = familyMartData.cvsspot;
          this.cartForm.cvsnum = familyMartData.cvsnum;
          this.cartForm.family_name = familyMartData.name;
          clearInterval(this.intervalFamilyMart);
        }
      }, 1000);
    },
    getFilter() {
      const getCounty = new Promise((resolve, reject) => {
        const result = requestApi('req.getCounty');
        result.then((res) => {
          if (res.status) {
            const arr = [];
            res.data.forEach((element) => {
              arr.push(element.county);
            });
            resolve(arr);
          } else {
            reject(new Error('發生例外錯誤:無法取得地址資料'));
          }
        });
      });
      getCounty.then((countyOptions) => {
        this.county = countyOptions;
        const county = this.cartForm.county ? this.cartForm.county : countyOptions[0];
        console.log(this.cartForm);
        const getDistrict = new Promise((resolve, reject) => {
          const result = requestApi('req.getDistrict', { county });
          result.then((res) => {
            if (res.status) {
              const arr = [];
              this.zipData = res.data;
              res.data.forEach((element) => {
                arr.push(element.district);
              });
              resolve(arr);
            } else {
              reject(new Error('發生例外錯誤:無法取得地址資料'));
            }
          });
        });
        getDistrict.then((districtOptions) => {
          console.log(districtOptions);
          this.district = districtOptions;
        });
      });
    },
    getPhoneCode() {
      const getCountryPhoneCode = new Promise((resolve, reject) => {
        const result = requestApi('req.getCountryPhoneCode');
        result.then((res) => {
          if (res.status) {
            const arr = [];
            res.data.forEach((element) => {
              arr.push({
                text: element.phone_code_name,
                id: element.phone_code,
              });
            });
            resolve(arr);
          } else {
            reject(new Error('發生例外錯誤:無法取得國碼資料'));
          }
        });
      });
      getCountryPhoneCode.then((countryPhoneCode) => {
        this.countryPhoneCode = countryPhoneCode;
      });
    },
  },
  watch: {
    cartForm(newVal, oldVal) {
      const changeAddress = new Promise((resolve) => {
        if (newVal !== oldVal) {
          resolve(true);
        }
      });
      changeAddress.then((value) => {
        if (value) {
          this.getFilter();
        }
      });
    },
  },
  mounted() {
    this.getPhoneCode();
  },
};
</script>
<style lang="scss" scpoe>
  .inputRow {
    display: flex;
    align-items: center;
  }
  /* Chrome, Safari, Edge, Opera */
  input.inputNumber {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  /* Firefox */
  input.inputNumber[type=number] {
    -moz-appearance: textfield;
  }
</style>
