<template>
  <div id="cartDetail">
    <CartShip
      @county="county"
      @district="district"
      @zipcode="zipcode"
      @selectType="selectType"
      @historyData="historyData"
      :historyAddress="historyAddress"
      :cartForm="cartForm"/>
    <CartInvoice
      :cartForm="cartForm"
      :acceptRefund="acceptRefund"
      :acceptTerms="acceptTerms"
      @selectPayment="selectPayment"
      @invoiceType="invoiceType"
      @acceptRefund="passAcceptRefund"
      @acceptTerms="passAcceptTerms"/>
    <CartMain />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import CartMain from '@/components/cart/CartMain.vue';
import CartShip from '@/components/cart/CartShip.vue';
import CartInvoice from '@/components/cart/CartInvoice.vue';
import requestApi from '@/lib/http/index';

export default {
  name: 'CartDetail',
  components: {
    CartMain,
    CartShip,
    CartInvoice,
  },
  data() {
    return {
      memberAddress: {},
      historyAddress: [],
      cartForm: {
        flight_abroad: null,
        take_way_id: null,
        return_way_id: null,
        take_option_id: null,
        return_option_id: null,
        name: null,
        sex: 'm',
        email: null,
        country_code: null,
        mobile: null,
        tel: null,
        tel_1: null,
        payment_id: null,
        invoice_type: 1,
        invoice_style: 1,
        carrier_type: null,
        carrier_code: null,
        company_no: null,
        donate_org_code: null,
        content: null,
        cvsspot: null,
        cvsnum: null,
        family_name: null,
      },
      discountEvent: {
        value: null,
        result: false,
        message: null,
        data: null,
      },
      acceptRefund: false,
      acceptTerms: false,
      currentCartType: null,
      cartsHasRent: null,
    };
  },
  computed: {
    ...mapState([
      'order',
      'takeWay',
      'totalPriceAndCount',
      'user',
      'isLogin',
    ]),
  },
  methods: {
    ...mapMutations([
      'setTotalPriceAndCount',
      'setOrder',
      'setOrderDetail',
      'setAcceptRefund',
      'setAcceptTerms',
    ]),
    passAcceptTerms(value) {
      this.acceptTerms = value;
    },
    passAcceptRefund(value) {
      this.acceptRefund = value;
    },
    invoiceType(value) {
      this.cartForm.invoice_type = value;
    },
    selectPayment(id) {
      this.cartForm.payment_id = id;
    },
    selectType(e) {
      const type = parseInt(e.target.value, 10);
      if (type === 1) {
        // eslint-disable-next-line
        const { county, district, address, zipcode } = this.memberAddress;
        this.cartForm.county = county;
        this.cartForm.district = district;
        this.cartForm.address = address;
        this.cartForm.zipcode = zipcode;
      } else if (type === 2) {
        this.getHistoryAddress();
      } else {
        this.resetAddress();
      }
    },
    async getHistoryAddress() {
      // eslint-disable-next-line
      const member_id = this.user.id;
      const result = await requestApi('cart.getHistoryAddress', { member_id });
      console.log(result);
      if (result.status) {
        this.historyAddress = result.data;
      } else {
        this.$customSWAL({
          icon: 'error',
          title: '錯誤',
          text: result.message,
          confirmButtonText: '確定',
        });
      }
    },
    resetAddress() {
      this.cartForm.county = '臺北市';
      this.cartForm.district = '中正區';
      this.cartForm.zipcode = '100';
      this.cartForm.address = null;
    },
    zipcode(v) {
      this.cartForm.zipcode = v;
    },
    county(e) {
      this.cartForm.county = e.target.value;
      this.cartForm.district = null;
      this.cartForm.zipcode = null;
    },
    district(e) {
      console.log(e.target);
      this.cartForm.district = e.target.value;
      this.cartForm.zipcode = null;
    },
    historyData(data) {
      // eslint-disable-next-line
      const { county, district, zipcode, address, ...others } = this.cartForm;
      this.cartForm = {
        county: data.county,
        district: data.district,
        zipcode: data.zipcode,
        address: data.address,
        ...others,
      };
    },
    putOrderData() {
      const order = JSON.parse(localStorage.getItem('order'));
      if (order) {
        /* eslint-disable camelcase */
        const {
          flight_abroad, take_option_id, return_option_id, take_way_id, return_way_id,
        } = order;
        this.cartForm.flight_abroad = flight_abroad;
        this.cartForm.take_option_id = take_option_id;
        this.cartForm.return_option_id = return_option_id;
        this.cartForm.take_way_id = take_way_id;
        this.cartForm.return_way_id = return_way_id;
        this.setOrder(this.cartForm);
      }
    },
    async getMemberData() {
      if (this.takeWay === '宅配') {
        // eslint-disable-next-line
        const member_id = this.user.id;
        const result = await requestApi('cart.getMemberAddress', { member_id });
        console.log(result);
        if (result.status) {
          // eslint-disable-next-line
          const { county, district, zipcode, address } = result.data;
          this.memberAddress = {
            county,
            district,
            zipcode,
            address,
          };
          this.cartForm = {
            county,
            district,
            zipcode,
            address,
            ...this.cartForm,
          };
          this.setOrder(this.cartForm);
        } else {
          this.$customSWAL({
            icon: 'error',
            title: '錯誤',
            text: '無法取得會員聯絡地址',
            confirmButtonText: '確定',
          });
        }
      }
    },
  },
  created() {
    if (this.isLogin) {
      this.putOrderData();
      this.getMemberData();
    }
  },
  watch: {
    takeWay() {
      this.getMemberData();
    },
    acceptRefund(val) {
      this.setAcceptRefund(val);
    },
    acceptTerms(val) {
      this.setAcceptTerms(val);
    },
  },
};
</script>
